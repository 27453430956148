<template>
  <div class="container flex flex-col flex-1">
    <AppointmentFormNCP
      v-if="step === SCHEDULE_MEDICAL_STEP.SELECT_SLOT"
      @setApptData="setApptData"
      @handleStep="handleStep"
    />
    <AppointmentConfirm
      v-if="step === SCHEDULE_MEDICAL_STEP.CONFIRM"
      :apptData="apptData"
      @handleStep="handleStep"
    />
  </div>
</template>

<script>
import AppointmentFormNCP from './AppointmentFormNCP.vue'
import AppointmentConfirm from './AppointmentConfirm.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'MedicalRegisterSchedule',
  components: { AppointmentFormNCP, AppointmentConfirm },
  data () {
    const SCHEDULE_MEDICAL_STEP = {
      SELECT_SLOT: 1,
      CONFIRM: 2
    }

    return {
      SCHEDULE_MEDICAL_STEP,
      step: SCHEDULE_MEDICAL_STEP.SELECT_SLOT,
      apptData: {}
    }
  },
  created () {
    if (!Object.keys(this.userStateData).length) {
      this.$router
        .replace({
          name: 'ExaminationRegisterForm'
        })
        .catch(() => {})
    }
    if (!Object.keys(this.selectedWs).length) {
      this.$router
        .replace({
          name: 'ExaminationRegister'
        })
        .catch(() => {})
    }
  },
  computed: {
    ...mapGetters('registerMedical', ['userStateData', 'selectedWs'])
  },
  methods: {
    handleStep (step = 1) {
      this.step = step
    },
    setApptData (data) {
      this.apptData = { ...data }
    }
  }
}
</script>

<style>
</style>
