<template>
  <div class="flex flex-col flex-1">
    <b-overlay
      :show="loading"
      :opacity="0.85"
      variant="white"
      spinner-variant="primary"
      rounded="sm"
      blur="none"
      class="flex flex-col flex-1"
      overlay-tag="div"
    >
      <div class="relative mt-3 mt-md-5 mb-3">
        <h1 class="m-0 text-center text-pri-900">Đặt lịch khám</h1>

        <el-tooltip
          class="absolute top-1/2 left-0 transform -translate-y-1/2"
          :content="$t('Quay lại chọn phòng khám & bác sĩ')"
          placement="right"
        >
          <div
            @click="backStep"
            role="button"
            class="inline-block w-10 h-10 bg-white rounded-circle overflow-hidden"
          >
            <span
              class="flex w-10 h-10 align-middle border rounded-circle hover:bg-blue-100 transition-all duration-200"
            >
              <i class="el-icon-back m-auto text-xl" />
            </span>
          </div>
        </el-tooltip>
      </div>

      <div class="row" v-if="appt">
        <div class="col-md-5">
          <div class="flex items-center mb-3">
            <div class="w-8 text-center">
              <i class="el-icon-school text-3xl" style="color: #20409B" />
            </div>
            <p
              class="fs-20 font-medium ml-2 mb-0"
              style="color: #20409B;"
            >{{ appt.doctor.cr_info.name }}</p>
          </div>
          <div class="flex items-center mb-3">
            <div class="w-8 text-center">
              <i class="el-icon-location-information text-3xl" style="color: #20409B;" />
            </div>
            <p class="fs-20 font-medium ml-2 mb-0" style="color: #20409B;">{{ selectedWs.address }}</p>
          </div>

          <div class="flex items-center mb-3">
            <div class="w-8 text-center">
              <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6.61027 7.68792C4.83325 9.4648 4 12.4273 4 17.3487C4 22.27 4.83325 25.2325 6.61027 27.0094C8.38732 28.7863 11.3498 29.6192 16.2705 29.6192C21.192 29.6192 24.1549 28.7863 25.9321 27.0094C27.7093 25.2325 28.5425 22.2701 28.5425 17.3487C28.5425 12.4273 27.7093 9.46482 25.9321 7.68795C24.1549 5.91104 21.192 5.07812 16.2705 5.07812C11.3498 5.07812 8.38732 5.91103 6.61027 7.68792ZM5.19612 6.27365C7.5663 3.90368 11.2391 3.07812 16.2705 3.07812C21.3027 3.07812 24.9758 3.90367 27.3462 6.27362C29.7166 8.64362 30.5425 12.3164 30.5425 17.3487C30.5425 22.3809 29.7166 26.0537 27.3462 28.4237C24.9758 30.7937 21.3027 31.6192 16.2705 31.6192C11.2391 31.6192 7.5663 30.7936 5.19612 28.4237C2.82593 26.0537 2 22.3809 2 17.3487C2 12.3164 2.82593 8.64364 5.19612 6.27365Z"
                  fill="#20409B"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2.39453 12.4062C2.39453 11.854 2.84225 11.4062 3.39453 11.4062H29.1629C29.7152 11.4062 30.1629 11.854 30.1629 12.4062C30.1629 12.9585 29.7152 13.4062 29.1629 13.4062H3.39453C2.84225 13.4062 2.39453 12.9585 2.39453 12.4062Z"
                  fill="#20409B"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M21.5742 18.043C21.5742 17.4907 22.0219 17.043 22.5742 17.043H22.5871C23.1394 17.043 23.5871 17.4907 23.5871 18.043C23.5871 18.5953 23.1394 19.043 22.5871 19.043H22.5742C22.0219 19.043 21.5742 18.5953 21.5742 18.043Z"
                  fill="#20409B"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.2793 18.043C15.2793 17.4907 15.727 17.043 16.2793 17.043H16.2922C16.8445 17.043 17.2922 17.4907 17.2922 18.043C17.2922 18.5953 16.8445 19.043 16.2922 19.043H16.2793C15.727 19.043 15.2793 18.5953 15.2793 18.043Z"
                  fill="#20409B"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.9707 18.043C8.9707 17.4907 9.41842 17.043 9.9707 17.043H9.98358C10.5359 17.043 10.9836 17.4907 10.9836 18.043C10.9836 18.5953 10.5359 19.043 9.98358 19.043H9.9707C9.41842 19.043 8.9707 18.5953 8.9707 18.043Z"
                  fill="#20409B"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M21.5742 23.5547C21.5742 23.0024 22.0219 22.5547 22.5742 22.5547H22.5871C23.1394 22.5547 23.5871 23.0024 23.5871 23.5547C23.5871 24.107 23.1394 24.5547 22.5871 24.5547H22.5742C22.0219 24.5547 21.5742 24.107 21.5742 23.5547Z"
                  fill="#20409B"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.2793 23.5547C15.2793 23.0024 15.727 22.5547 16.2793 22.5547H16.2922C16.8445 22.5547 17.2922 23.0024 17.2922 23.5547C17.2922 24.107 16.8445 24.5547 16.2922 24.5547H16.2793C15.727 24.5547 15.2793 24.107 15.2793 23.5547Z"
                  fill="#20409B"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.9707 23.5547C8.9707 23.0024 9.41842 22.5547 9.9707 22.5547H9.98358C10.5359 22.5547 10.9836 23.0024 10.9836 23.5547C10.9836 24.107 10.5359 24.5547 9.98358 24.5547H9.9707C9.41842 24.5547 8.9707 24.107 8.9707 23.5547Z"
                  fill="#20409B"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M22.0068 1C22.5591 1 23.0068 1.44772 23.0068 2V6.66772C23.0068 7.22001 22.5591 7.66772 22.0068 7.66772C21.4546 7.66772 21.0068 7.22001 21.0068 6.66772V2C21.0068 1.44772 21.4546 1 22.0068 1Z"
                  fill="#20409B"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10.5488 1C11.1011 1 11.5488 1.44772 11.5488 2V6.66772C11.5488 7.22001 11.1011 7.66772 10.5488 7.66772C9.99654 7.66772 9.54883 7.22001 9.54883 6.66772V2C9.54883 1.44772 9.99654 1 10.5488 1Z"
                  fill="#20409B"
                />
              </svg>
            </div>
            <p
              class="fs-20 font-medium ml-2 mb-0"
              style="color: #20409B;"
            >{{ appUtils.formatGroupDateTime(appt.form.StartTime) }}</p>
          </div>
          <div class="flex items-center mb-3">
            <div class="w-8 text-center">
              <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M16.0417 3.9593C9.37248 3.9593 3.9593 9.37248 3.9593 16.0417C3.9593 22.7109 9.37248 28.124 16.0417 28.124C22.7109 28.124 28.124 22.7109 28.124 16.0417C28.124 9.37248 22.7109 3.9593 16.0417 3.9593ZM2 16.0417C2 8.29039 8.29039 2 16.0417 2C23.7929 2 30.0833 8.29039 30.0833 16.0417C30.0833 23.7929 23.7929 30.0833 16.0417 30.0833C8.29039 30.0833 2 23.7929 2 16.0417Z"
                  fill="#20409B"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.5588 9.19531C16.0998 9.19531 16.5384 9.63392 16.5384 10.175V15.5304C16.5384 15.731 16.615 16.0217 16.7853 16.3203C16.9556 16.6189 17.1667 16.8326 17.3383 16.9343L17.3409 16.9358L17.3409 16.9358L21.3901 19.3523C21.8547 19.6296 22.0066 20.231 21.7293 20.6956C21.452 21.1602 20.8506 21.312 20.386 21.0348L16.3394 18.6198C16.3389 18.6196 16.3385 18.6193 16.338 18.619C15.8049 18.3027 15.3764 17.8049 15.0833 17.2909C14.7899 16.7764 14.5791 16.1527 14.5791 15.5304V10.175C14.5791 9.63392 15.0177 9.19531 15.5588 9.19531Z"
                  fill="#20409B"
                />
              </svg>
            </div>
            <p
              class="fs-20 font-medium ml-2 mb-0"
              style="color: #20409B;"
            >{{ appt.form.SlotDuration }} phút</p>
          </div>
        </div>
        <div class="col-md-7 border py-3">
          <div class="flex flex-col lg:flex-row items-start mb-3">
            <div class="flex items-center mb-1 w-1/2">
              <div class="w-7 text-center">
                <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10.2751 21.6128C8.76475 22.6041 8.03479 23.8014 8.03479 24.8482C8.03479 25.3513 8.1968 25.722 8.48929 26.0345C8.80456 26.3713 9.31333 26.6816 10.0549 26.9375C11.5506 27.4536 13.688 27.6474 16.1264 27.6474C18.5762 27.6474 20.7125 27.4467 22.2036 26.9248C22.9427 26.6661 23.4496 26.3533 23.7639 26.0143C24.0561 25.6992 24.2179 25.3255 24.2179 24.8207C24.2179 23.7779 23.486 22.5874 21.971 21.6012C20.4843 20.6335 18.4016 19.983 16.1264 19.983C13.8391 19.983 11.7577 20.6397 10.2751 21.6128ZM9.13978 19.8831C10.9829 18.6734 13.4645 17.9141 16.1264 17.9141C18.7736 17.9141 21.2539 18.6658 23.0996 19.8673C24.917 21.0502 26.2869 22.7958 26.2869 24.8207C26.2869 25.8492 25.9266 26.7248 25.281 27.4211C24.6575 28.0935 23.8108 28.5543 22.887 28.8776C21.0524 29.5197 18.6256 29.7164 16.1264 29.7164C13.6423 29.7164 11.2167 29.5271 9.37998 28.8933C8.45531 28.5742 7.60552 28.1179 6.97878 27.4483C6.32926 26.7544 5.96582 25.8789 5.96582 24.8482C5.96582 22.8273 7.32434 21.0747 9.13978 19.8831Z"
                    fill="#20409B"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.23246 8.84727C9.2334 5.04644 12.3156 1.96484 16.1166 1.96484C19.9185 1.96484 22.9996 5.04767 22.9996 8.84902C22.9996 12.6507 19.9183 15.732 16.1166 15.732H16.0763L16.0719 15.732C12.2817 15.7191 9.22056 12.6371 9.23246 8.84727ZM16.1166 4.03381C13.4577 4.03381 11.3014 6.19005 11.3014 8.84902V8.85253C11.2924 11.5002 13.4301 13.6532 16.0773 13.663H16.1157C18.7747 13.663 20.9306 11.508 20.9306 8.84902C20.9306 6.18977 18.7753 4.03381 16.1166 4.03381Z"
                    fill="#20409B"
                  />
                </svg>
              </div>
              <div class="fs-16 ml-2">{{ $t('Bệnh nhân') }}</div>
            </div>
            <div class="fs-20 font-medium txt-pri-900 lg:ml-auto text-right">
              <span>{{ userStateData.fullName }}</span>
              <span class="mx-1">-</span>
              <span class="capitalize">{{ appUtils.getAges(userStateData.dateOfBirth) }}</span>
            </div>
          </div>

          <div class="flex flex-col lg:flex-row items-start mb-3">
            <div class="flex items-center mb-1 w-1/4">
              <div class="w-7 text-center">
                <i class="el-icon-location-information text-2xl" style="color: #20409B;" />
              </div>
              <div class="fs-16 ml-2">{{ $t('Địa chỉ') }}</div>
            </div>
            <div class="fs-20 font-medium txt-pri-900 lg:ml-auto text-right">
              <span>{{ userStateData.address }}</span>
            </div>
          </div>

          <!-- <div class="flex flex-col lg:flex-row items-start mb-3">
          <div class="flex items-center mb-1 w-1/4">
            <div class="w-7 text-center">
              <i class="el-icon-first-aid-kit text-2xl" style="color: #20409B" />
            </div>
            <div class="fs-16 ml-2">{{ $t('Phòng Tư vấn') }}</div>
          </div>
          <div class="fs-20 font-medium txt-pri-900 lg:ml-auto text-right">
            <span>{{ appt.doctor.cr_info.name }}</span>
          </div>
          </div>-->

          <div class="flex flex-col lg:flex-row items-start mb-3">
            <div class="flex items-center mb-1 w-1/4">
              <div class="w-7 text-center">
                <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M21.998 11V5C21.998 3.346 20.652 2 18.998 2H12.998C11.344 2 9.99805 3.346 9.99805 5V11C9.99805 14.309 12.689 17 15.998 17C19.307 17 21.998 14.309 21.998 11ZM11.998 5C11.998 4.449 12.447 4 12.998 4H18.998C19.549 4 19.998 4.449 19.998 5V6H11.998V5ZM11.998 11V8H19.998V11C19.998 11.179 19.968 11.35 19.945 11.523C18.864 10.564 17.467 10 15.998 10C14.529 10 13.132 10.564 12.051 11.523C12.028 11.35 11.998 11.179 11.998 11ZM15.998 12C17.22 12 18.339 12.554 19.093 13.502C18.359 14.408 17.252 15 15.998 15C14.744 15 13.637 14.408 12.903 13.502C13.657 12.554 14.776 12 15.998 12V12Z"
                    fill="#20409B"
                  />
                  <path
                    d="M23 18.0039H9C6.243 18.0039 4 20.2469 4 23.0039V29.0039C4 29.5559 4.448 30.0039 5 30.0039H27C27.552 30.0039 28 29.5559 28 29.0039V23.0039C28 20.2469 25.757 18.0039 23 18.0039ZM26 28.0039H6V23.0039C6 21.3499 7.346 20.0039 9 20.0039H11V21.1459C9.28 21.5929 8 23.1459 8 25.0039V26.0039C8 26.5559 8.448 27.0039 9 27.0039H10C10.552 27.0039 11 26.5559 11 26.0039C11 25.4519 10.552 25.0039 10 25.0039C10 23.9009 10.897 23.0039 12 23.0039C13.103 23.0039 14 23.9009 14 25.0039C13.448 25.0039 13 25.4519 13 26.0039C13 26.5559 13.448 27.0039 14 27.0039H15C15.552 27.0039 16 26.5559 16 26.0039V25.0039C16 23.1459 14.72 21.5929 13 21.1459V20.0039H20V22.2809C19.405 22.6269 19 23.2649 19 24.0039C19 25.1089 19.895 26.0039 21 26.0039C22.105 26.0039 23 25.1089 23 24.0039C23 23.2659 22.595 22.6279 22 22.2809V20.0039H23C24.654 20.0039 26 21.3499 26 23.0039V28.0039Z"
                    fill="#20409B"
                  />
                </svg>
              </div>
              <div class="fs-16 ml-2">{{ $t('Bác sĩ') }}</div>
            </div>
            <div class="fs-20 font-medium txt-pri-900 lg:ml-auto text-right">
              <span>{{ appt.doctor.doctor_info.User.name }}</span>
            </div>
          </div>

          <div class="flex flex-col items-start mb-3">
            <div class="flex items-center mb-1 w-full md:w-1/4">
              <div class="w-7 text-center">
                <i class="el-icon-chat-square text-2xl" style="color: #20409B" />
              </div>
              <div class="fs-16 ml-2">{{ $t('Lý do khám') }}</div>
            </div>
            <div class="w-full">
              <el-input
                type="textarea"
                v-model="appt.form.Reason"
                :autosize="{ minRows: 4, maxRows: 6 }"
                autocomplete="off"
              ></el-input>
            </div>
          </div>

          <div class="flex flex-col lg:flex-row items-start mb-3">
            <div class="flex items-center mb-1 w-full md:w-1/4">
              <div class="w-7 text-center">
                <i class="el-icon-money text-2xl" style="color: #20409B" />
              </div>
              <div class="fs-16 ml-2">{{ $t('Đơn giá') }}</div>
            </div>
            <div class="fs-20 font-medium text-red-500 lg:ml-auto">
              <span>{{ appt.form.SlotPrice ? appUtils.numberFormat(appt.form.SlotPrice, 'VNĐ') : 'Hỗ trợ' }}</span>
            </div>
          </div>

          <div>
            <button
              class="btn w-100 bg-pri-900 text-white cursor-pointer uppercase"
              @click="submit"
              :disabled="loading"
            >
              <span class="spinner-border spinner-border-sm mr-2" role="status" v-if="loading"></span>
              <span>Đăng ký lịch khám</span>
            </button>
          </div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import appUtils from '../../../utils/appUtils'

export default {
  name: 'AppointmentConfirm',
  props: {
    apptData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      appt: this.apptData || {},
      appUtils
    }
  },
  computed: {
    ...mapGetters('registerMedical', [
      'defaultWs',
      'userStateData',
      'selectedWs'
    ])
  },
  watch: {
    apptData: {
      handler (data) {
        this.appt = data
      }
    }
  },
  methods: {
    backStep () {
      this.$emit('handleStep', 1)
    },
    async submit () {
      const payload = {
        ...this.appt.form
      }
      console.log(payload)
      this.$store
        .dispatch('registerMedical/bookAppointment', payload)
        .then(response => {
          console.log(response.data)
          const apptId = response.data?.data?.id
          this.$router.replace({
            path: `/detail-schedule-service/${apptId}`
          })
        })
        .catch(e => {
          console.log(e)
          window.$toast.open({
            message: 'Lỗi đặt lịch khám',
            type: 'danger'
          })
        })
    }
  }
}
</script>
<style lang="css" scoped></style>
