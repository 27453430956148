<template>
  <div class="flex flex-col flex-1">
    <b-overlay
      :show="loading"
      :opacity="0.85"
      variant="white"
      spinner-variant="primary"
      rounded="sm"
      blur="none"
      class="flex flex-col flex-1"
      overlay-tag="div"
    >
      <div class="relative mt-3 mt-md-5 mb-3">
        <h1 class="m-0 text-center text-pri-900">Đặt lịch khám</h1>

        <el-tooltip
          class="absolute top-1/2 left-0 transform -translate-y-1/2"
          :content="$t('Quay lại chọn nơi khám')"
          placement="right"
        >
          <div
            @click="goToSelectWs"
            role="button"
            class="inline-block w-10 h-10 bg-white rounded-circle overflow-hidden"
          >
            <span
              class="flex w-10 h-10 align-middle border rounded-circle hover:bg-blue-100 transition-all duration-200"
            >
              <i class="el-icon-back m-auto text-xl" />
            </span>
          </div>
        </el-tooltip>
      </div>
      <div>
        <div class="mb-2 font-bold txt-black">Lọc thông tin</div>
        <div class="mb-3 border bg-gray-100 p-3 rounded-lg">
          <b-row>
            <b-col md="4" class="mb-2 mb-md-0">
              <div class="mb-1 text-sm">Tìm kiếm Bác sĩ</div>
              <el-input
                clearable
                placeholder="Tìm Bác sĩ"
                v-model="searchInput"
                class="input-with-select"
                @input="debounceSearch"
                @clear="clearDataSearch"
                @keyup.enter.native="handleSearchDoctor"
              >
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="onHandleSubmitSearchDoctor"
                ></el-button>
              </el-input>
            </b-col>
            <b-col md="4" class="mb-2 mb-md-0">
              <div class="mb-1 text-sm">Chọn phòng khám</div>
              <el-select
                default-first-option
                v-model="selectedRoom"
                placeholder="Chọn phòng khám"
                class="w-full"
                @change="handleSelectRoom"
              >
                <el-option
                  v-for="item in roomOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </b-col>
            <b-col md="4">
              <div class="mb-1 text-sm">Chọn dịch vụ</div>
              <el-select
                default-first-option
                v-model="selectedService"
                placeholder="Chọn dịch vụ"
                class="w-full"
                @change="handleSelectServiceRoom"
              >
                <el-option
                  v-for="item in serviceRooms"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </b-col>
          </b-row>
        </div>
      </div>
      <b-row class="flex-1">
        <b-col md="8">
          <div class="mb-2 font-bold txt-black">Chọn phòng khám & Bác sĩ</div>
          <div v-if="err_doctor" class="text-danger">{{ err_doctor }}</div>
          <div>
            <b-row v-if="doctorsOptions.length > 0">
              <b-col
                md="6"
                class="mb-3"
                v-for="doctor in doctorsOptions"
                :key="doctor.id"
              >
                <div
                  class="flex cursor-pointer border rounded p-3 h-full hover:bg-blue-50 transition-all duration-200"
                  :class="{ 'bg-blue-50': doctor.id === selectedDoctor.id }"
                  @click="selectDoctor(doctor.id)"
                >
                  <div class="flex-1">
                    <p class="mb-1 font-bold text-lg text-black">
                      {{ doctor.doctor_info && doctor.doctor_info.User.name }}
                    </p>
                    <p class="mb-1 font-bold text-sm text-black">
                      {{ doctor.cr_info.name }}
                    </p>
                    <p class="mb-1 text-sm text-black">
                      <i class="el-icon-location txt-pri-900 me-1"></i>
                      <span>{{ doctor.cr_info.address }}</span>
                    </p>
                  </div>
                  <div>
                    <div class="w-20 h-20 p-1 rounded-full bg-blue-100">
                      <img
                        class="avatar avatar-sm avatar-rounded object-cover"
                        :src="
                          getImageUrl(
                            doctor.doctor_info && doctor.doctor_info.User.avatar
                          )
                        "
                        @error="(e) => appUtils.onHandleErrorLoadImage(e)"
                      />
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
            <div v-else>
              <b-alert show variant="default" class="text-center mb-0"
                >Không tìm thấy thông tin Bác sĩ</b-alert
              >
            </div>
            <AppPagination
              class="mb-4"
              :paginationProps="paginationProps"
              @onChangePagination="onChangePagination"
            />
          </div>
        </b-col>
        <b-col md="4">
          <div class="flex flex-col h-full">
            <div class="w-full">
              <div class="mb-2 font-bold txt-black">Chọn thời gian</div>
              <div class="mb-3">
                <div
                  class="d-flex justify-content-center align-items-center py-5"
                  v-if="loading_date"
                >
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
                <v-date-picker
                  v-show="!loading_date"
                  v-model="selected_date"
                  @input="selectDate()"
                  :attributes="datePickerAttributes"
                  :min-date="new Date()"
                  is-expanded
                  @update:from-page="changeDatePickerParams"
                />
              </div>
            </div>
            <div class="flex flex-col appt-time-slot">
              <div class="mb-2 font-bold txt-black w-full">
                Chọn khung giờ ngày: {{ selected_date_formatDMY || "&nbsp;" }}
              </div>
              <div
                class="d-flex justify-content-center align-items-center py-5"
                v-if="loading_time"
              >
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <div class="overflow-x-hidden overflow-y-auto">
                <div
                  class="w-full"
                  v-if="(!timeslots || !timeslots.length) && !loading_time"
                >
                  <b-alert show variant="default" class="text-center mb-0"
                    >Không có lịch trống</b-alert
                  >
                </div>
                <b-row>
                  <b-col
                    :cols="6"
                    :class="`${
                      index === timeslots.length - 1 && timeslots.length % 2
                        ? 'w-100'
                        : ''
                    } ${item.count === 0 ? 'cursor-not-allowed disabled' : ''}`"
                    v-for="(item, index) in timeslots"
                    :key="index"
                  >
                    <div
                      class="group relative flex time-slot time-slot-mouseover"
                      @click="item.count > 0 ? selectedTime(item) : () => {}"
                    >
                      <div class="w-full">
                        <div
                          class="h-100 d-flex justify-content-center align-items-center border-1 border-solid border-blue-800 text-blue-800 group-hover:bg-blue-50 transition-all duration-200"
                          :class="
                            item.count === 0 ? 'bg-gray-300 opacity-50' : ''
                          "
                        >
                          <p class="robo-20-500 mb-0">
                            {{ item.label.replace("-", " - ") }}
                          </p>
                        </div>
                      </div>
                      <div
                        class="time-slot--select w-full h-full absolute top-0 left-0 z-10 transition-all duration-200"
                      >
                        <div
                          class="h-100 d-flex justify-content-center align-items-center bg-pri"
                        >
                          <p class="robo-20-500 mb-0 text-white">Chọn</p>
                        </div>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import appUtils from '../../../utils/appUtils'
import AppPagination from '../../../components/Common/AppPagination.vue'
import { USE_ROOM_PRICE_CR } from '@/utils/constants'

export default {
  name: 'AppointmentFormNCP',
  // props: ['personData'],
  components: {
    AppPagination
  },
  data () {
    return {
      loading: true,
      doctorsOptions: [],
      selectedDoctor: {},
      loading_date: false,
      loading_time: false,
      datePickerAttributes: [],
      datePickerParams: {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1
      },
      selected_date: moment().locale('vi').toDate(),
      timeslots: [],
      selected_time: null,
      err_doctor: '',
      doctorsPagination: {
        page_num: 1,
        page_size: 10,
        total: 0,
        total_page: 0
      },
      searchInput: '',
      roomOptions: [
        {
          value: 'all',
          label: 'Tất cả'
        }
      ],
      selectedRoom: 'all',
      serviceRooms: [
        {
          value: 'all',
          label: 'Tất cả'
        }
      ],
      selectedService: 'all',
      typing: false,
      debounce: null,
      appUtils,
      interval: null
    }
  },
  async beforeMount () {
    this.fetchDoctors()
    await this.getListConsultingRooms()
    await this.getClinicalServices()
  },
  mounted () {
    this.handleIntervalGetSlot()
  },
  computed: {
    ...mapGetters('registerMedical', [
      'defaultWs',
      'userStateData',
      'selectedWs'
    ]),
    selected_date_formatDMY () {
      return this.selected_date
        ? window.moment(this.selected_date).format('DD/MM/YYYY')
        : null
    },
    disabledScroll () {
      return (
        this.loading_date ||
        this.loading_time ||
        this.doctorsPagination.page_num === this.doctorsPagination.total_page
      )
    },
    paginationProps () {
      return {
        totalItems: this.doctorsPagination.total,
        pagerCount: 5,
        pageSize: this.doctorsPagination.page_size,
        currentPage: this.doctorsPagination.page_num
      }
    }
  },
  methods: {
    handleIntervalGetSlot () {
      this.clearInterval()
      this.interval = setInterval(() => {
        this.selectDate()
      }, 1000 * 30)
    },
    clearInterval () {
      clearInterval(this.interval)
      this.interval = null
    },
    fetchDoctors () {
      if (!this.selectedWs?.id) return
      try {
        this.loading = true
        const params = {
          keyword: this.searchInput || '',
          page_num: this.doctorsPagination.page_num,
          page_size: this.doctorsPagination.page_size,
          ws_id: this.selectedWs?.id,
          cr_id: this.selectedRoom === 'all' ? null : this.selectedRoom,
          clinical_service_id:
            this.selectedService === 'all' ? null : this.selectedService,
          is_public: 1
        }
        this.$store
          .dispatch('registerMedical/fetchDoctors', params)
          .then(async (response) => {
            this.doctorsOptions = [
              // ...this.doctorsOptions,
              ...response.data?.data
            ]
            this.doctorsPagination = response.data?.page
            this.doctorsOptions[0]?.id &&
              (await this.selectDoctor(this.doctorsOptions[0]?.id))
          })
          .catch((e) => {
            console.log(e)
            window.$toast.open({
              message: 'Lỗi lấy danh sách bác sĩ',
              type: 'danger'
            })
          })
        this.loading = false
      } catch (error) {
        console.log(error)
        this.loading = false
      }
    },
    async selectDoctor (did) {
      if (this.selectedDoctor?.id === did) return
      this.err_doctor = null
      this.selectedDoctor = this.doctorsOptions.find((doc) => doc.id === did)
      try {
        this.loading_date = true
        await this.checkDoctorAvailableByMonth()
        this.selected_date = new Date()
      } catch (error) {
        console.log(error)
      }
      this.loading_date = false
    },
    async checkDoctorAvailableByMonth () {
      this.loading_date = true
      if (
        !this.selectedDoctor?.doctor_id ||
        !this.selectedDoctor?.consulting_room_id
      ) {
        this.loading_date = false
        return
      }
      this.datePickerAttributes = []

      const params = {
        DoctorID: this.selectedDoctor.doctor_id,
        CrId: this.selectedDoctor.consulting_room_id,
        Month: this.datePickerParams.month,
        Year: this.datePickerParams.year
      }

      try {
        const resp = await this.$rf
          .getRequest('GuestRequest')
          .checkAvailableDoctorByMonthCr(params)
        resp.data.result.forEach((x) => {
          if (x.IsOn === 1 && x.DayInMonth >= moment().date()) {
            const indexDate = {
              highlight: 'green',
              dates: null
            }
            indexDate.dates = new Date(
              this.datePickerParams.year,
              this.datePickerParams.month - 1,
              x.DayInMonth
            )
            this.datePickerAttributes = [
              ...this.datePickerAttributes,
              indexDate
            ]
          }
        })
      } catch (e) {
        // statements
        console.log(e)
      } finally {
        this.loading_date = false
      }
    },
    selectDate () {
      if (this.selectedDoctor?.id) {
        // this.timeslots = []
        if (this.selected_date) this.getTimeSlot()
        // clear giờ đã chọn trước đây
        this.selected_time = null
      } else {
        this.err_doctor = 'Chưa chọn bác sĩ'
      }
    },
    changeDatePickerParams (e) {
      this.datePickerParams.month = e.month
      this.datePickerParams.year = e.year
      this.checkDoctorAvailableByMonth()
    },
    async getTimeSlot () {
      const date = moment(this.selected_date).format('YYYY-MM-DD')
      const params = {
        DoctorID: String(this.selectedDoctor.doctor_id),
        Date: date,
        CurrentTime: moment().format('YYYY-MM-DD HH:mm'),
        CrID: String(this.selectedDoctor.consulting_room_id)
      }
      this.loading_time = false
      this.$store
        .dispatch('registerMedical/fetchTimeSlotV2', params)
        .then((response) => {
          this.timeslots = response.data
          this.loading_time = false
        })
        .catch((e) => {
          console.log(e)
          window.$toast.open({
            message: 'Lỗi lấy danh sách thời gian khám',
            type: 'danger'
          })
        })
    },
    selectedTime (item) {
      if (!item?.slots || item?.slots?.length === 0) {
        window.$toast.open({
          message: 'Không thể chọn khung thời gian này',
          type: 'danger'
        })
        return
      }

      this.selected_time = item.slots[0]

      const payload = {
        WorkspaceId: this.selectedWs?.id,
        CrId: this.selectedDoctor.consulting_room_id,
        DoctorID: this.selectedDoctor.doctor_id,
        StartTime: appUtils.formatInputDateTime(
          `${this.moment(this.selected_date).format('YYYY-MM-DD')} ${
            this.selected_time.Start
          }`
        ),
        EndTime: appUtils.formatInputDateTime(
          `${this.moment(this.selected_date).format('YYYY-MM-DD')} ${
            this.selected_time.End
          }`
        ),
        SlotPrice:
          this.selectedDoctor.cr_info?.is_using_room_price ===
            USE_ROOM_PRICE_CR.isNotUse &&
          this.selectedDoctor?.service_local?.local_price
            ? this.selectedDoctor?.service_local?.local_price
            : this.selectedDoctor.cr_info?.is_using_room_price.slot_price,
        SlotDuration: this.selectedDoctor.cr_info.slot_duration,
        Reason: '',
        PersonId: parseInt(this.userStateData?.id),
        Method: 1,
        LineNumber: this.selected_time.LineNumber
      }

      const appt = {
        form: { ...payload },
        doctor: { ...this.selectedDoctor }
      }

      this.$emit('setApptData', appt)
      this.$emit('handleStep', 2)
      // this.submit()
    },
    async submit () {
      const payload = {
        WorkspaceId: this.selectedWs?.id,
        CrId: this.selectedDoctor.consulting_room_id,
        DoctorID: this.selectedDoctor.doctor_id,
        StartTime: appUtils.formatInputDateTime(
          `${this.moment(this.selected_date).format('YYYY-MM-DD')} ${
            this.selected_time.Start
          }`
        ),
        EndTime: appUtils.formatInputDateTime(
          `${this.moment(this.selected_date).format('YYYY-MM-DD')} ${
            this.selected_time.End
          }`
        ),
        SlotPrice: this.selectedDoctor.cr_info.slot_price,
        SlotDuration: this.selectedDoctor.cr_info.slot_duration,
        Reason: '',
        PersonId: parseInt(this.$route.query?.personId),
        Method: 1,
        LineNumber: this.selected_time.LineNumber
      }
      this.$store
        .dispatch('registerMedical/bookAppointment', payload)
        .then((response) => {
          console.log(response.data)
          const apptId = response.data?.data?.id
          this.$router.replace({
            path: `/detail-schedule-service/${apptId}`
          })
        })
        .catch((e) => {
          console.log(e)
          window.$toast.open({
            message: 'Lỗi đặt lịch khám',
            type: 'danger'
          })
        })
    },
    onChangePagination (data) {
      this.doctorsPagination.page_num = data.currentPage
      this.doctorsPagination.page_size =
        data.pageSize || this.doctorsPagination.page_size
      this.fetchDoctors()
    },
    handleSearchDoctor () {
      this.fetchDoctors()
    },
    onHandleSubmitSearchDoctor () {
      if (!this.searchInput || this.searchInput === '') return
      this.fetchDoctors()
    },
    clearDataSearch () {
      this.searchInput = ''
      this.fetchDoctors()
    },
    debounceSearch () {
      this.typing = true
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.typing = false
        this.handleSearchDoctor()
      }, 600)
    },
    goToSelectWs () {
      this.$router
        .push({
          name: 'ExaminationRegister'
        })
        .catch(() => {})
    },
    getImageUrl (path) {
      return appUtils.getDocumentURL(path)
    },
    async getListConsultingRooms () {
      if (!this.selectedWs?.id) return
      try {
        const res = await this.$rf
          .getRequest('AuthRequest')
          .getPublicConsultingRoomsByWsId(this.selectedWs?.id)
        if (!res.data?.data) return
        const rooms = res.data.data.map((item) => ({
          value: item.id,
          label: item.name
        }))
        this.roomOptions = [
          {
            value: 'all',
            label: 'Tất cả'
          },
          ...rooms
        ]
      } catch (error) {
        console.log(error)
      }
    },
    async getClinicalServices () {
      try {
        /**
         *  page_size: số phần tử trong trang
            page_num; thứ tự trang
            keyword: tên service
            status: 1. Chưa hoạt động 2. Đã hoạt động
            org_id: id tổ chức
            sort: sắp xếp theo trường
            sort_by: asc/desc
         */
        const params = {
          page_size: 100,
          page_num: 1,
          // keyword: '',
          status: 2,
          org_id: this.defaultWs?.org_id
        }
        const res = await this.$rf
          .getRequest('AuthRequest')
          .getClinicalServices(params)
        // console.log(res.data)
        const services = res.data.data.map((item) => ({
          value: item.id,
          label: item.title
        }))
        this.serviceRooms = [
          {
            value: 'all',
            label: 'Tất cả'
          },
          ...services
        ]
      } catch (err) {
        console.log(err)
      }
    },
    handleSelectRoom () {
      this.fetchDoctors()
    },
    handleSelectServiceRoom () {
      this.fetchDoctors()
    }
  },
  beforeDestroy () {
    this.clearInterval()
  }
}
</script>

<style lang="scss" scoped>
.appt-time-slot {
  max-height: calc(100vh - 310px);

  @media only screen and (min-width: 1200px) {
    max-height: calc(100vh - 535px);
  }
}
.time-slot {
  height: 40px;
  margin-bottom: 10px;
  cursor: pointer;
  &--select {
    opacity: 0;
    visibility: hidden;
  }
  &:hover &--select {
    opacity: 1;
    visibility: visible;
  }
}
.time-slot-mouseover .time {
  background-color: #8a8a8a;
}

.avatar {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #f0f2f6 no-repeat center/cover;
}

/* width */
::-webkit-scrollbar {
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
  border-radius: 5px;
  gap: 15px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
